@import "bootstrap/bootstrap";
html {
  scroll-behavior: smooth;
}

@font-face {
  font-family: "Bookantiqua";
  src: url("fonts/BookAntiquaRegular.woff") format("embedded-opentype"),
    url("fonts/BookAntiquaRegular.woff") format("woff"),
    url("fonts/BookAntiquaRegular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
body {
  font-family: "Barlow", sans-serif;
  font-size: 19px;
  background-color: $black;
  margin: 0;
  padding: 0;
}
.msgie {
  display: none;
  * > p {
    width: 100%;
    text-align: center;
    color: red;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .msgie {
    display: block;
  }
}
h1,
h2,
h3,
h3,
h4,
h5,
h6 {
  font-family: Bookantiqua;
}
h1 {
  font-size: 32px;
  text-transform: uppercase;
}
.btn {
  &:focus,
  &:active,
  &:hover {
    box-shadow: none;
  }
}
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.services .btn_more:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.services .btn_more:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus,
.services .show > .dropdown-toggle.btn_more:focus,
.btn-secondary:focus,
.services .btn_more:focus,
.btn-secondary.focus,
.services .focus.btn_more {
  box-shadow: none !important;
  outline: none !important;
  &:focus,
  &:active,
  &:hover {
    box-shadow: none !important;
    outline: none !important;
  }
}
button:focus {
  outline: none !important;
  box-shadow: none !important;
}
.btn-secondary {
  font-family: "Barlow", sans-serif;
  color: $white;
  text-transform: capitalize;
  font-size: 22px;
  line-height: 35px;
  border: none;
  &:after {
    background-image: url(../images/btn_arrow.png);
    background-size: 45px 30px;
    background-repeat: no-repeat;
    display: inline-block;
    width: 57px;
    height: 27px;
    content: "";
    top: 4px;
    position: relative;
    right: -11px;
  }
}
.btn_more {
  border: none;
  box-shadow: none;
}
.container-fluid {
  padding-right: 0;
  padding-left: 0;
}
.bg-black {
  background-color: black;
}
.row {
  padding-top: 25px;
  padding-bottom: 30px;
  //@media (min-width: 992px){
  margin: 0;

  &.even {
    color: $white;
    background-color: $black;
    & form {
      background: transparent;
      color: $white;
      .form-control {
        color: $white;
        &:focus {
          color: $white;
          background-color: #666;
        }
      }
    }
    & a {
      color: white;
    }
  }
  &.odd {
    color: $black;
    background-color: $white;
    & form {
      background: transparent;
      color: $black;
      .form-control {
        &:focus {
          background-color: #bbb;
        }
      }
    }
  }
}
.jumbotron {
  margin-bottom: 0;
  padding: 1rem 2rem 0.4rem 2rem;
  @media (min-width: 992px) {
    padding: 1rem 2rem 1.2rem 2rem;
  }
  font-family: Bookantiqua;
  .display-1 {
    font-size: 205px;
    line-height: 183px;
    margin-top: 30px;
    @media (max-width: 992px) {
      font-size: 70px;
      line-height: 70px;
      margin-top: 5px;
    }
  }
  .lead {
    font-size: 20px;
    text-transform: uppercase;
    @media (max-width: 992px) {
      font-size: 18px;
    }
  }
}
nav {
  @media (max-width: 992px) {
    /*margin-right: -15px;
    margin-left: -15px;  */
  }
  .row {
    width: 100%;
    margin-left: 0;
    @media (max-width: 992px) {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
  &.navbar-light {
    background-color: $black;
    padding-bottom: 1.5rem;
    @media (max-width: 992px) {
      padding-bottom: 0;
    }
    & > * {
      color: $white;
      font-family: "Barlow", sans-serif;
    }
    #navbarNav {
      text-align: center;
    }
    .nav-main {
      margin: auto;
      .nav-item {
        margin-right: 1rem;
        @media (max-width: 992px) {
          margin-right: 0;
        }
        &:last-of-type {
          margin-right: 0;
        }
        .nav-link {
          font-size: 22px;
          color: $white;
          word-break: keep-all;
          white-space: nowrap;
          @media (max-width: 992px) {
            font-size: 32px;
            text-align: center;
          }
          &.active,
          &:hover {
            color: $gray-400;
          }
        }
      }
      .active > .nav-link {
        color: $gray-400;
      }
    }

    .navbar-toggler {
      margin: auto;
      font-size: 0.9rem;
      @media (max-width: 992px) {
        display: block;
      }
      color: $white;
      border-color: $black;
      .navbar-toggler-icon {
        width: 3em;
        height: 3em;
      }
      & > .close {
        width: 32px;
        height: 32px;
        background-image: url("../images/close.png");
        background-size: 32px 32px;
        background-position: center;
        background-repeat: no-repeat;
        display: inline;
        color: $white;
        padding: 0;
      }
    }
    .navbar-toggler.collapsed > .close,
    .navbar-toggler:not(.collapsed) > .navbar-toggler-icon {
      display: none;
    }
  }

  .nav-lang {
    color: $white;
    list-style-type: none;
    margin: 0;
    padding: 0;
    z-index: 600;
    @media (max-width: 992px) {
      font-size: 24px;
    }
    li {
      float: left;
      margin-top: 6px;
      a {
        display: block;
        color: white;
        text-align: center;
        padding: 5px;
        text-decoration: none;
        text-transform: uppercase;
      }
    }
  }
  &.small-navbar {
    .row {
      padding: 0;
    }
    .navbar-toggler {
      font-size: 0.7rem;
    }
    .nav-lang {
      font-size: 20px;
    }
    @supports not (position: sticky) {
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 999;
    }
  }
}
.page-element {
  position: relative;
  .anchor_name {
    position: absolute;
    top: -100px;
    @media (max-width: 992px) {
      top: -450px;
    }
    display: block;
  }
}
/****** CARD  */
.card {
  border: none;
  .card-title {
    font-family: Bookantiqua;
    font-size: 22px;
    margin-bottom: 2.75rem;
    word-break: break-word;
    text-align: center;
    @include media-breakpoint-only(lg) {
      font-size: 1.5vw;
    }
    @include media-breakpoint-down(md) {
      width: 9em;
      margin-left: auto;
      margin-right: auto;
    }
    @include media-breakpoint-only(md) {
      font-size: 110%;
    }
    @include media-breakpoint-down(sm) {
      font-size: 120%;
    }
  }
  .card-text {
    margin-top: 2em;
    display: none;
    @include media-breakpoint-up(md) {
      display: block;
    }
    p {
      white-space: nowrap;
      font-size: 16px;
    }
  }
}
.services {
  figure {
    img {
      height: 136px;
      widows: auto;
      max-width: 100%;
    }
  }
  .btn_more {
    width: 225px;
    margin: auto;
    @extend .btn-secondary;
    display: inline-block;
    @include media-breakpoint-up(md) {
      display: none;
    }
  }
}

.infos {
  .container {
    padding-top: 2rem;
    padding-bottom: 2rem;
    h1 {
      margin-bottom: 2rem;
    }
    figure {
      margin-right: 1rem;
      margin-left: 1rem;
      margin-bottom: 2.5rem;
      img {
        max-height: 500px;
        max-width: 100%;
      }
      &.fig_logo {
        img {
          max-height: 90px;
        }
        @include media-breakpoint-down(sm) {
          margin-right: 0.5rem;
          margin-left: 0.5rem;
          img {
            max-height: 50px;
          }
        }
      }
    }
  }
}
.honeypot {
  position: absolute;
  left: -9999px;
}
form {
  .form-control {
    padding: 15px;
    background: transparent;
    font-size: 22px;
    line-height: 22px;
    margin: 2rem 0;
    height: auto;
  }
  textarea {
    min-height: 200px;
    height: 100%;
  }
  textarea::placeholder,
  input::placeholder {
    font-size: 22px;
    text-transform: capitalize;
  }
}

.footer {
  margin: 0;
  background-color: $black;
  color: $white;
  font-size: 0.8em;
}
